import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useHandleError } from "../../../hooks";
import { GET_SC_USER } from "../../../graphql/queries";
import { parsePatientSc } from "../../../graphql/utils";
import { PatientScDetailPage as Component, } from "../../../components/pages/patientSc/PatientScDetailPage";
import { useSnackBar } from "../../../hooks/useSnackBar";
import { FORCE_CANCEL_PRESCRIPTION } from "../../../graphql/mutations";
import { useCheckUserRole } from "../../../hooks/useCheckUserRole";
export const PatientScDetailPage = () => {
    const history = useHistory();
    useCheckUserRole();
    const { snackBar, showOKSnackBar } = useSnackBar();
    // @ts-ignore idが存在するのに「ない」と怒られるので
    const { id: patientId } = useParams();
    if (!patientId)
        throw new Error("patientId is undefined");
    const { error, data } = useQuery(GET_SC_USER, {
        variables: { id: patientId },
    });
    const [forceCancelPrescription] = useMutation(FORCE_CANCEL_PRESCRIPTION, {
        onCompleted: (result) => {
            var _a;
            if (((_a = result.forceCancelPrescription) === null || _a === void 0 ? void 0 : _a.__typename) ===
                "ForceCancelPrescriptionError") {
                history.push({
                    pathname: `/cancelPrescription/error`,
                    state: {
                        message: result.forceCancelPrescription.message,
                        prescriptionCode: result.forceCancelPrescription.prescriptionCode,
                    },
                });
            }
            else {
                showOKSnackBar("処方をキャンセルしました。");
            }
        },
        onError: () => {
            history.push({
                pathname: `/cancelPrescription/error`,
                state: {
                    prescriptionCode: data === null || data === void 0 ? void 0 : data.scUser.prescriptionCode,
                },
            });
        },
    });
    useHandleError(error);
    if (!(data === null || data === void 0 ? void 0 : data.scUser)) {
        return null;
    }
    const handleCancelPrescriptionCodeButtonClick = async () => {
        const isOk = confirm("本当に処方をキャンセルしますか？キャンセルしたら元には戻せません。");
        if (isOk) {
            await forceCancelPrescription({
                variables: {
                    input: {
                        prescriptionCode: data === null || data === void 0 ? void 0 : data.scUser.prescriptionCode,
                    },
                },
            });
        }
    };
    const props = {
        patient: parsePatientSc(data.scUser),
        onCancelPrescriptionCodeButtonClick: handleCancelPrescriptionCodeButtonClick,
        snackBar,
    };
    return React.createElement(Component, Object.assign({}, props));
};
