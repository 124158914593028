import React, { useState, useRef, useEffect, } from "react";
import styled from "styled-components";
import arrowDown from "../../assets/icons/arrow_down.png";
import { useOnClickOutside } from "../../hooks/UseOnClickOutside";
import { ErrorMessage } from "./ErrorMessage";
import { hexToRGB } from "../../utils/style";
const Container = styled.div `
  position: relative;
  border-radius: 6px;
`;
const Header = styled.div `
  display: flex;
  background-color: ${(props) => props.error
    ? hexToRGB(props.theme.colors.caution, 0.05)
    : props.theme.colors.inputFieldBackground};
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  border: 1px solid
    ${(props) => props.error
    ? props.theme.colors.caution
    : props.isOpen
        ? props.theme.colors.brand
        : props.theme.colors.primaryBorder};
  border-radius: 6px;
  ${(props) => props.isOpen && `border-bottom: none; border-radius: 6px 6px 0 0`};
  padding: 10px 0 10px 10px;
  min-height: 44px;
  ${(props) => props.disabled && `opacity: 0.3`};
`;
const HeaderInner = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: ${(props) => (props.isSelected ? 1 : 0.7)};
  width: 100%;
`;
const ListContainer = styled.div `
  cursor: pointer;
  position: absolute;
  left: 0;
  background-color: ${(props) => props.theme.colors.primaryBackground};
  border: 1px solid ${(props) => props.theme.colors.brand};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: none;
  width: 100%;
  z-index: 1;
  div:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  height: 150px;
  overflow: scroll;
`;
const Icon = styled.img `
  margin-right: 10px;
`;
const ItemWrapper = styled.div `
  padding: 10px;
  &:hover {
    background-color: #e1f3fd;
  }
`;
export function SelectList({ dataId, placeholder, disabled, items, value, onValueChange, error, errorMessages, onCollapse, }) {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState(null);
    useEffect(() => {
        if (items.length > 0) {
            const selectedItem = items.find((item) => item.props.value === value);
            setSelected((selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.props) || null);
        }
    }, [value, items]);
    useOnClickOutside(ref, () => setIsOpen(false));
    const toggleSelected = (item, event) => {
        setIsOpen(!isOpen);
        setSelected(item.props);
        onValueChange(item.props.value, event.currentTarget.getAttribute("data-id"));
    };
    const handleClick = () => {
        onCollapse && isOpen && onCollapse();
        setIsOpen(!isOpen);
    };
    return (React.createElement(Container, { ref: ref },
        React.createElement(Header, { isOpen: isOpen, disabled: disabled, onClick: handleClick, error: error },
            React.createElement(HeaderInner, { isSelected: !!selected },
                selected ? selected.children : placeholder,
                React.createElement(Icon, { src: arrowDown, width: "20", height: "20", alt: "arrow_down" }))),
        isOpen && !disabled && (React.createElement(ListContainer, null, items.length > 0 &&
            items.map((item, index) => (React.createElement(ItemWrapper, { "data-id": dataId, key: index, onClick: (event) => toggleSelected(item, event) }, item.props.children))))),
        React.createElement(ErrorMessage, { errorMessage: errorMessages && errorMessages.find((message) => message !== "") })));
}
SelectList.defaultProps = {
    disabled: false,
};
export function SelectListItem(props) {
    return React.createElement("div", Object.assign({}, props), props.children);
}
