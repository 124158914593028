import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { UpdateEmailAddressPage as Component, } from "../../../components/pages/patientHt/UpdateEmailAddressPage";
import { useMutation } from "@apollo/client";
import { UPDATE_HT_USER_EMAIL_ADDRESS } from "../../../graphql/mutations";
import { useLocation, useHistory } from "react-router-dom";
import { useValidation } from "../../../hooks/useValidation";
import { GET_MODAL_STATE } from "../../../graphql/queries";
import { closeModal } from "../../../graphql/local-mutations";
import { useCheckUserRole } from "../../../hooks/useCheckUserRole";
export const UpdateEmailAddressPage = () => {
    const history = useHistory();
    const location = useLocation();
    const htPatient = location.state;
    useCheckUserRole();
    const { data: modalData } = useQuery(GET_MODAL_STATE);
    const [snackBar, setSnackBar] = useState(null);
    const showErrorSnackBar = (message) => {
        setSnackBar({
            type: "error",
            message,
            isOpen: true,
            close() {
                setSnackBar(null);
            },
        });
    };
    const [updatedEmailAddress, { loading }] = useMutation(UPDATE_HT_USER_EMAIL_ADDRESS, {
        onCompleted: () => {
            closeModal("confirmInput");
            history.push(`/patient_ht/${htPatient.id}`);
        },
        onError: () => {
            showErrorSnackBar("アドレスの更新に失敗しました。DTFチームにお問い合わせください。");
            closeModal("confirmInput");
        },
    });
    const [proposedEmail, setProposedEmail] = useState("");
    const { errors: validationErrors, isFormValid, setTouched } = useValidation({
        proposedEmail: {
            value: proposedEmail,
            rules: { email: true },
        },
    }, [proposedEmail]);
    const onUpdateEmailAddressClick = (email) => {
        updatedEmailAddress({
            variables: {
                input: {
                    id: htPatient.id,
                    email,
                },
            },
        });
    };
    return (React.createElement(Component, Object.assign({ htPatient: {
            id: htPatient.id,
            email: htPatient.email,
        }, onUpdateEmailAddressClick: onUpdateEmailAddressClick, validationErrors: validationErrors, setProposedEmail: (email) => {
            setTouched("proposedEmail");
            setProposedEmail(email);
        } }, {
        proposedEmail,
        isFormValid,
        processing: loading,
        snackBar,
        onCancelButtonClick: () => {
            history.push(`/patient_ht/${htPatient.id}`);
        },
    }, { isOpen: (modalData === null || modalData === void 0 ? void 0 : modalData.modalState.confirmInput.isOpen) || false })));
};
