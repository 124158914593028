import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import styled from "styled-components";
import { hexToRGB } from "../../utils/style";
import { TypographyStyle } from "../atoms/Typography";
import { defaultTheme } from "../themes";
const StyledTable = styled.table `
  border-radius: 6px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.primaryBackground};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  tr {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
  }
  th,
  td {
    display: flex;
    vertical-align: middle;
    text-align: left;
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px;
  }
`;
export function DataTable(props) {
    return React.createElement(StyledTable, null, props.children);
}
const StyledHeader = styled.thead `
  background-color: ${(props) => hexToRGB(props.theme.colors.secondaryBackground, 0.08)};
  tr {
    height: 45px;
  }
`;
const StyledHeaderItem = styled.th `
  flex-grow: ${(props) => props.flex};
  width: 0;
  ${({ alignItems }) => alignItems && `justify-content: ${alignItems};`}
`;
export function DataListHeader(props) {
    const { row, flexRatio } = props;
    return (React.createElement(StyledHeader, null,
        React.createElement("tr", null, row.props.children.map((item, index) => (React.createElement(StyledHeaderItem, { flex: flexRatio[index], key: index, alignItems: item.props.align }, item))))));
}
const StyledSimpleTableRow = styled.tr `
  padding: 12px 10px;
  border-bottom: 2px solid ${(props) => props.theme.colors.primaryBorder};
`;
const StyledSimpleTableHeader = styled.th `
  flex-grow: ${(props) => props.flex};
  width: 0;
  ${TypographyStyle}
`;
export function DataListSimpleHeader(props) {
    const { titles, flexRatio } = props;
    return (React.createElement("thead", null,
        React.createElement(StyledSimpleTableRow, null, titles.map((title, index) => (React.createElement(StyledSimpleTableHeader, { flex: flexRatio[index], key: title, bold: true, size: 2 }, title))))));
}
const IconWrapper = styled.div `
  color: ${(props) => hexToRGB(defaultTheme.colors.secondaryBackground, props.opacity)};
`;
const HeaderItemWrapper = styled.div `
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover ${IconWrapper} {
    color: ${hexToRGB(defaultTheme.colors.secondaryBackground, 0.54)};
  }
`;
const TitleInner = styled.div `
  opacity: 90%;
  margin-right: 15px;
`;
export const SwitchHeaderOrder = (field, order) => ({
    field,
    value: (order === null || order === void 0 ? void 0 : order.field) !== field
        ? "DESC" /* Desc */
        : order.value === "ASC" /* Asc */
            ? "DESC" /* Desc */
            : "ASC" /* Asc */,
});
export function DataListHeaderItem(props) {
    const { onSort, order } = props;
    return (React.createElement(HeaderItemWrapper, { onClick: onSort },
        React.createElement(TitleInner, null, props.children),
        React.createElement(IconWrapper, { opacity: order === undefined ? 0.1 : 0.54 }, onSort && (React.createElement(FontAwesomeIcon, { icon: "caret-up", rotation: order === "ASC" /* Asc */ ? undefined : 180 })))));
}
const StyledBody = styled.tbody `
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  tr {
    border-bottom: 1px solid ${(props) => props.theme.colors.primaryBorder};
    cursor: pointer;
    &:hover {
      background-color: ${(props) => props.theme.colors.hoverBackground};
    }
    z-index: 1;
  }
`;
export function DataListBody({ rows }) {
    const rowClick = useCallback((row) => {
        row.props.onRowClick && row.props.onRowClick();
    }, []);
    const mouseOver = useCallback((row) => {
        row.props.onMouseOver && row.props.onMouseOver();
    }, []);
    const mouseLeave = useCallback((row) => {
        row.props.onMouseLeave && row.props.onMouseLeave();
    }, []);
    return (React.createElement(StyledBody, null, rows.map((row, index) => (React.createElement("tr", { key: index, onClick: () => rowClick(row), onMouseOver: () => mouseOver(row), onMouseLeave: () => mouseLeave(row) }, row)))));
}
const BodyItemWrapper = styled.td `
  min-height: 58px;
  align-items: center;
  flex-grow: ${(props) => props.flex};
  width: 0;
`;
export function DataListRow(props) {
    const { children, flexRatio } = props;
    return (React.createElement(React.Fragment, null, children.map((item, index) => (React.createElement(BodyItemWrapper, { key: index, flex: flexRatio[index] }, item)))));
}
const StyledBodyItem = styled.div `
  align-items: center;
  text-align: ${({ textAlign }) => textAlign || "left"};
  flex-grow: 2;
  width: 0;
`;
export function DataListBodyItem(props) {
    return (React.createElement(StyledBodyItem, { textAlign: props.textAlign }, props.children));
}
