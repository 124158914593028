import React from "react";
import styled, { css } from "styled-components";
const getFontStyle = (props) => {
    switch (props.size) {
        case 1:
            return {
                size: props.theme.fontSize.xsmall,
                letterSpacing: 0,
            };
        case 2:
            return {
                size: props.theme.fontSize.small,
                letterSpacing: 0,
            };
        case 3:
            return {
                size: props.theme.fontSize.medium,
                letterSpacing: 0,
            };
        case 4:
            return {
                size: props.theme.fontSize.large,
                letterSpacing: "0.05em",
            };
        case 5:
            return {
                size: props.theme.fontSize.xlarge,
                letterSpacing: 0,
            };
        case 6:
            return {
                size: props.theme.fontSize.xxlarge,
                letterSpacing: "0.04em",
            };
        default:
            return {
                size: props.theme.fontSize.medium,
                letterSpacing: 0,
            };
    }
};
export const TypographyStyle = css `
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  font-size: ${(props) => getFontStyle(props).size};
  letter-spacing: ${(props) => getFontStyle(props).letterSpacing};
  line-height: 1.2;
  ${(props) => props.textRight && `text-align: right; width: 100%;`}
  ${(props) => props.breakWord && `word-break: break-all;`};
  ${(props) => props.withEllipsis &&
    `overflow: hidden; text-overflow: ellipsis; white-space: nowrap;`};
`;
const StyledText = styled.p `
  ${TypographyStyle}
`;
Typography.defaultProps = {
    size: 3,
    bold: false,
    breakWord: false,
    withEllipsis: false,
};
export function Typography(props) {
    return React.createElement(StyledText, Object.assign({}, props), props.children);
}
