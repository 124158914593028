import React from "react";
import { useHistory } from "react-router-dom";
import { useListDataWithLoadMore } from "../../../hooks";
import { GET_SC_USERS } from "../../../graphql/queries";
import { parseScPatients } from "../../../graphql/utils";
import { PatientScPage as Component, } from "../../../components/pages/patientSc/PatientScPage";
import { useCheckUserRole } from "../../../hooks/useCheckUserRole";
export const PatientScPage = () => {
    const history = useHistory();
    useCheckUserRole();
    const { data, variables: { keyword, order }, handlers: { onSearchClick, onResetClick, onSortClick, onLoadMoreClick }, } = useListDataWithLoadMore(GET_SC_USERS, "scUsers", "処方情報は全て表示されています");
    if (!(data === null || data === void 0 ? void 0 : data.scUsers)) {
        return null;
    }
    const props = {
        patients: parseScPatients(data.scUsers),
        searchValue: keyword,
        order,
        onSearchClick,
        onResetClick,
        onSortClick,
        onRowClick: (patientId) => history.push(`patient/${patientId}`),
        onLoadMoreClick,
    };
    return React.createElement(Component, Object.assign({}, props));
};
