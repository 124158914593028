import React from "react";
import styled from "styled-components";
import { PageBody } from "../PageBody";
import { Typography } from "../../atoms/Typography";
import { List, ListItem } from "../../atoms/List";
import { hexToRGB } from "../../../utils/style";
import userIcon from "../../../assets/images/users/sample.png";
const ContentWrapper = styled.div `
  padding: 0 40px;
`;
const Inner = styled.div `
  padding: 40px 0 20px;
  display: flex;
  ${(props) => hexToRGB(props.theme.colors.primaryBorder, 0.5)};
`;
const LeftBlock = styled.div `
  flex: 1;
  padding-right: 40px;
`;
const MemberIconAndName = styled.div `
  display: flex;
  align-items: center;
  img {
    margin-right: 20px;
  }
`;
const RightBlock = styled.div `
  flex: 1;
  padding-left: 40px;
`;
const PaddingRightWrapper = styled.div `
  width: 100%;
  padding-right: 20px;
`;
const TextRightTypography = (props) => (React.createElement(PaddingRightWrapper, null,
    React.createElement(Typography, { textRight: true, size: 2 }, props.children)));
export function DeliveryOrderDetailPage({ deliveryOrder, products, }) {
    var _a;
    return (React.createElement(React.Fragment, null,
        React.createElement(PageBody, { title: "\u7269\u54C1\u60C5\u5831\u8A73\u7D30" },
            React.createElement(React.Fragment, null,
                React.createElement(ContentWrapper, null,
                    React.createElement(Inner, null,
                        React.createElement(LeftBlock, null,
                            React.createElement(MemberIconAndName, null,
                                React.createElement("img", { src: userIcon, width: "72", height: "72", alt: "user_icon" }),
                                React.createElement(Typography, { size: 5, breakWord: true }, deliveryOrder.name))))),
                React.createElement(ContentWrapper, null,
                    React.createElement(Inner, null,
                        React.createElement(LeftBlock, null,
                            React.createElement(List, null,
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u90F5\u4FBF\u756A\u53F7"),
                                    React.createElement(TextRightTypography, null, deliveryOrder.postalCode)),
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u4F4F\u6240"),
                                    React.createElement(TextRightTypography, null, deliveryOrder.address)),
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u914D\u9001\u65E5"),
                                    React.createElement(TextRightTypography, null, deliveryOrder.deliveryDate || "-")))),
                        React.createElement(RightBlock, null,
                            React.createElement(List, null,
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u96FB\u8A71\u756A\u53F7"),
                                    React.createElement(TextRightTypography, null, deliveryOrder.phoneNumber)),
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "\u914D\u9001\u756A\u53F7"),
                                    React.createElement(TextRightTypography, null, (deliveryOrder.deliveryHistory.length > 0 && ((_a = deliveryOrder.deliveryHistory[0]) === null || _a === void 0 ? void 0 : _a.slipNumber)) ||
                                        "-")),
                                React.createElement(ListItem, null,
                                    React.createElement(Typography, { bold: true, size: 1 }, "-"),
                                    React.createElement(TextRightTypography, null, "-")))))),
                products))));
}
