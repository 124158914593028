import { useQuery } from "@apollo/client";
import React from "react";
import { BrowserRouter, Switch, Route, Redirect, } from "react-router-dom";
import { AuthContainer } from "./containers/pages/auth/AuthContainerTmp";
import { LogoutCompleted } from "./components/pages/auth/LogoutCompleted";
import { ErrorPage } from "./components/pages/ErrorPage";
import { NotFoundPage } from "./components/pages/NotFoundPage";
import { Header } from "./containers/organisms/Header";
import { MenuList } from "./containers/organisms/MenuList";
// import { AppPage } from "./containers/pages/AppPage";
// import { SessionExpiredModal } from "./containers/organisms/modals/SessionExpiredModal";
import { Snackbar } from "./containers/organisms/Snackbar";
import { GET_USER_STATE, GET_APP_STATE } from "./graphql/queries";
import { useHandleServerError } from "./hooks";
import { MedicalInstitutionPage } from "./containers/pages/medicalInstitution/MedicalInstitutionPage";
import { MedicalInstitutionDetailPage } from "./containers/pages/medicalInstitution/MedicalInstitutionDetailPage";
import { ResendWelcomeMailPage } from "./containers/pages/medicalInstitution/ResendWelcomeMailPage";
import { PatientScPage } from "./containers/pages/patientSc/PatientScPage";
import { PatientScDetailPage } from "./containers/pages/patientSc/PatientScDetailPage";
import { PatientHtPage } from "./containers/pages/patientHt/PatientHtPage";
import { PatientHtDetailPage } from "./containers/pages/patientHt/PatientHtDetailPage";
import { UpdateEmailAddressPage } from "./containers/pages/patientHt/UpdateEmailAddressPage";
import { PublishVerifyCodePage } from "./containers/pages/patientHt/PublishVerifyCodePage";
import { CompleteDeliveryOrderPage } from "./containers/pages/deliveryOrder/CompleteDeliveryOrderPage";
import { DeliveryOrderPage } from "./containers/pages/deliveryOrder/DeliveryOrderPage";
import { DeliveryOrderDetailPage } from "./containers/pages/deliveryOrder/DeliveryOrderDetailPage";
import { useAuthentication } from "./hooks/useAuthentication";
import { CreateJsonDeliveryOrderPage } from "./containers/pages/deliveryOrder/CreateJsonDeliveryOrderPage";
import { PrescriptionCancelPage } from "./containers/pages/prescription/PrescriptionCancelPage";
import { PrescriptionCancelErrorPage } from "./containers/pages/prescription/PrescriptionCancelErrorPage";
import { USER_ROLE } from "../src/utils/constants";
// TODO: ファイルアップロード機能でアップロードファイルが空になる問題が解消されたら表示する
// import { PdtPage } from "./containers/pages/pdt/PdtPage";
// import { PdtDetailPage } from "./containers/pages/pdt/PdtDetailPage";
// import { UploadPdtDocumentPage } from "./containers/pages/pdt/document/UploadPdtDocumentPage";
// import { CreatePdtDocumentPage } from "./containers/pages/pdt/document/CreatePdtDocumentPage";
// A wrapper for <Route> that redirects to the login screen if you're not yet authenticated.
// https://reacttraining.com/react-router/web/example/auth-workflow
const PrivateRoute = ({ children, ...rest }) => {
    const { data: userData } = useQuery(GET_USER_STATE);
    const { data: appData } = useQuery(GET_APP_STATE);
    const isSessionExpired = (userData === null || userData === void 0 ? void 0 : userData.userState.sessionExpired) || false;
    const { isNotFound, isForbidden, isServerError } = appData
        ? appData.appState
        : { isNotFound: false, isForbidden: false, isServerError: false };
    const isLoggedIn = userData === null || userData === void 0 ? void 0 : userData.userState.isLoggedIn;
    return (React.createElement(Route, Object.assign({}, rest, { render: ({ location }) => (() => {
            // 権限がなく見られないページは存在しないページとして扱った方がユーザーから見て自然なので、
            // forbiddenエラーが返された場合は404ページを表示する
            if (isForbidden || isNotFound)
                return React.createElement(NotFoundPage, null);
            if (isServerError)
                return React.createElement(ErrorPage, null);
            if (!isLoggedIn && !isSessionExpired)
                return (React.createElement(Redirect, { to: {
                        pathname: "/login",
                        state: { from: location },
                    } }));
            return children;
        })() })));
};
const RoutesWithNotFound = ({ userRole }) => {
    useHandleServerError();
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: "/login" },
            React.createElement(AuthContainer, null)),
        React.createElement(Route, { exact: true, path: "/logout" },
            React.createElement(LogoutCompleted, null)),
        React.createElement(PrivateRoute, { exact: true, path: "/" },
            React.createElement(Redirect, { to: userRole === USER_ROLE.CS_USER ? "/patient" : "/medicalInstitution" })),
        React.createElement(PrivateRoute, { path: "/deliveryOrder/complete/:id/:orderClassCode" },
            React.createElement(CompleteDeliveryOrderPage, null)),
        React.createElement(PrivateRoute, { path: "/deliveryOrder/create" },
            React.createElement(CreateJsonDeliveryOrderPage, null)),
        React.createElement(PrivateRoute, { path: "/deliveryOrder/:id" },
            React.createElement(DeliveryOrderDetailPage, null)),
        React.createElement(PrivateRoute, { path: "/deliveryOrder" },
            React.createElement(DeliveryOrderPage, null)),
        React.createElement(PrivateRoute, { exact: true, path: "/medicalInstitution" },
            React.createElement(MedicalInstitutionPage, null)),
        React.createElement(PrivateRoute, { path: "/medicalInstitution/:id/resendWelcomeMail" },
            React.createElement(ResendWelcomeMailPage, null)),
        React.createElement(PrivateRoute, { path: "/medicalInstitution/:id" },
            React.createElement(MedicalInstitutionDetailPage, null)),
        React.createElement(PrivateRoute, { exact: true, path: "/patient" },
            React.createElement(PatientScPage, null)),
        React.createElement(PrivateRoute, { path: "/patient/:id" },
            React.createElement(PatientScDetailPage, null)),
        React.createElement(PrivateRoute, { exact: true, path: "/patient_ht" },
            React.createElement(PatientHtPage, null)),
        React.createElement(PrivateRoute, { path: "/patient_ht/:id/updateEmailAddress" },
            React.createElement(UpdateEmailAddressPage, null)),
        React.createElement(PrivateRoute, { path: "/patient_ht/:id/publish-verify-code" },
            React.createElement(PublishVerifyCodePage, null)),
        React.createElement(PrivateRoute, { path: "/patient_ht/:id" },
            React.createElement(PatientHtDetailPage, null)),
        React.createElement(PrivateRoute, { path: "/cancelPrescription/error" },
            React.createElement(PrescriptionCancelErrorPage, null)),
        React.createElement(PrivateRoute, { path: "/cancelPrescription" },
            React.createElement(PrescriptionCancelPage, null)),
        React.createElement(Route, { path: "*" },
            React.createElement(NotFoundPage, null))));
};
export function Router() {
    useAuthentication();
    const { data: userData } = useQuery(GET_USER_STATE);
    const { data: appData } = useQuery(GET_APP_STATE);
    const { isHeaderVisible, isMenuListVisible, isNotSameSubDomain } = appData
        ? appData.appState
        : {
            isHeaderVisible: true,
            isMenuListVisible: true,
            isNotSameSubDomain: false,
        };
    const isLoggedIn = userData === null || userData === void 0 ? void 0 : userData.userState.isLoggedIn;
    // const onCreatePrescriptionClick = () => {
    //   disableScroll();
    //   openModal("createPrescription");
    // };
    return (React.createElement(BrowserRouter, null,
        isHeaderVisible && !isNotSameSubDomain && (React.createElement(React.Fragment, null,
            React.createElement(Header, null))),
        isMenuListVisible && isLoggedIn && !isNotSameSubDomain && (React.createElement(MenuList, { onCreatePrescriptionClick: () => undefined })),
        isLoggedIn && (React.createElement(React.Fragment, null)),
        React.createElement(RoutesWithNotFound, { userRole: (userData === null || userData === void 0 ? void 0 : userData.userState.userRole) || USER_ROLE.SS_USER }),
        React.createElement(Snackbar, null)));
}
