import React from "react";
import styled from "styled-components";
import { hexToRGB } from "../../utils/style";
const getColor = (props) => {
    switch (props.buttonType) {
        case "primary":
            return props.theme.colors.brand;
        case "danger":
            return props.theme.colors.caution;
        default:
            return props.theme.colors.primaryText;
    }
};
const getBackgroundColor = (props) => {
    if (props.ghost) {
        return props.theme.colors.primaryBackground;
    }
    else if (props.disabled) {
        return props.theme.colors.primaryText;
    }
    else {
        return getColor(props);
    }
};
const Inner = styled.div `
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
`;
const StyledButton = styled.button `
  width: 100%;
  height: 100%;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  outline: 0;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  border-radius: 6px;
  border: ${(props) => (props.ghost ? `2px solid ${getColor(props)}` : "none")};
  background-color: ${(props) => getBackgroundColor(props)};
  color: ${(props) => props.ghost ? getColor(props) : props.theme.colors.secondaryText};
  transition: background
    ${(props) => `${props.theme.interactions.primaryEasing} ${props.theme.interactions.primaryDuration}`};
  &:hover {
    background: ${(props) => hexToRGB(props.theme.colors.brand, 0.8)};
  }
`;
const StyledRoundButton = styled(StyledButton) `
  border-radius: 22px;
`;
Button.defaultProps = {
    disabled: false,
    ghost: false,
    buttonType: "default",
};
export function Button(props) {
    return (React.createElement(StyledButton, Object.assign({}, props),
        React.createElement(Inner, { disabled: props.disabled }, props.children)));
}
RoundButton.defaultProps = {
    disabled: false,
    ghost: false,
    buttonType: "default",
};
export function RoundButton(props) {
    return (React.createElement(StyledRoundButton, Object.assign({}, props),
        React.createElement(Inner, { disabled: props.disabled }, props.children)));
}
