import React from "react";
import { Input } from "../../atoms/Input";
import { Label } from "../../atoms/Label";
import { Typography } from "../../atoms/Typography";
import { Snackbar } from "../../atoms/Snackbar";
import styled from "styled-components";
import { PageBody } from "../PageBody";
import okIcon from "../../../assets/icons/ok.png";
import errorIcon from "../../../assets/icons/alert.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../../atoms/Button";
import { defaultTheme } from "../../themes";
const ContentWrapper = styled.div `
  padding: 20px 40px;
  display: flex;
  flex-wrap: wrap;
`;
const ItemWrapper = styled.div `
  ${(props) => props.bold && `font-weight: bold`};
  ${(props) => props.caution && `color: ${props.theme.colors.caution}`};
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom}`};
  ${(props) => props.width && `width: ${props.width}`};
  padding: 0 30px 5px 0;
`;
const ButtonWrapper = styled.div `
  ${(props) => props.marginTop && `margin-top: ${props.marginTop}`};
  width: ${(props) => props.width || "214px"};
  height: 44px;
`;
export function ResendWelcomeMailPage({ medicalInstitution, onResendWelcomeMailButtonClick, onResendWelcomeRegisteredMaiilButtonClick, resendWelcomeMailProcessing, resendWelcomeRegisteredMailProcessing, validationErrors, otherMailAddress, setOtherMailAddress, isFormValid, snackBar = null, onCancelButtonClick, isAdminVisible, }) {
    const inputProps = {
        onChange: (e) => setOtherMailAddress(e.target.value),
        value: otherMailAddress,
        error: !validationErrors.otherMailAddress.isValid,
        errorMessages: validationErrors.otherMailAddress.message,
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PageBody, { title: "ウェルカムメール再送", buttonTitle: "\u4E2D\u6B62", buttonType: "danger", buttonOutline: true, buttonIcon: React.createElement(FontAwesomeIcon, { icon: faTimes, color: defaultTheme.colors.caution }), onButtonClick: () => {
                onCancelButtonClick();
            } },
            React.createElement(React.Fragment, null,
                React.createElement(ContentWrapper, null,
                    React.createElement(ItemWrapper, { width: "100%", marginBottom: "10px" },
                        React.createElement(Label, { text: "医療機関ID", margin: "0 0 7px 0" }),
                        React.createElement("p", null, medicalInstitution.id)),
                    React.createElement(ItemWrapper, null,
                        React.createElement(Label, { text: "医療機関名", margin: "0 0 7px 0" }),
                        React.createElement("p", null, medicalInstitution.name)),
                    React.createElement(ItemWrapper, null,
                        React.createElement(Label, { text: "住所", margin: "0 0 7px 0" }),
                        React.createElement("p", null, medicalInstitution.address)),
                    React.createElement(ItemWrapper, null,
                        React.createElement(Label, { text: "代表者名", margin: "0 0 7px 0" }),
                        React.createElement("p", null, medicalInstitution.representativeName))),
                React.createElement(ContentWrapper, null,
                    React.createElement(ItemWrapper, { width: "100%", marginBottom: "12px" },
                        React.createElement(Label, { text: "登録メールアドレス", margin: "0 0 7px 0" }),
                        React.createElement("p", null, medicalInstitution.mailAddress)),
                    React.createElement(ItemWrapper, { width: "100%", caution: true, bold: true },
                        React.createElement("p", null, "\u30A6\u30A7\u30EB\u30AB\u30E0\u30E1\u30FC\u30EB\u306E\u518D\u9001\u3092\u884C\u3046\u3068APS\u958B\u8A2D\u6642\u306B\u521D\u671F\u767B\u9332\u3055\u308C\u3066\u3044\u308B\u30E1\u30F3\u30D0\u30FC\u306E\u30D1\u30B9\u30EF\u30FC\u30C9\u304C\u30EA\u30BB\u30C3\u30C8\u3055\u308C\u307E\u3059\u3002\u521D\u671F\u767B\u9332\u30E1\u30F3\u30D0\u30FC\u306B\u3064\u3044\u3066\u306F\u30A6\u30A7\u30EB\u30AB\u30E0\u30E1\u30FC\u30EB\u306B\u8A18\u8F09\u306E\u30B7\u30FC\u30AF\u30EC\u30C3\u30C8\u30B3\u30FC\u30C9\u3092\u4F7F\u7528\u3057\u3066\u518D\u5EA6\u30D1\u30B9\u30EF\u30FC\u30C9\u306E\u8A2D\u5B9A\u3092\u884C\u3046\u5FC5\u8981\u304C\u3042\u308B\u305F\u3081\u3001\u3059\u3067\u306B\u5229\u7528\u3055\u308C\u3066\u3044\u308B\u533B\u7642\u6A5F\u95A2\u3067\u518D\u9001\u3092\u884C\u3046\u5834\u5408\u306F\u3054\u6CE8\u610F\u304F\u3060\u3055\u3044\u3002")),
                    React.createElement(ButtonWrapper, { marginTop: "12px" },
                        React.createElement(Button, { onClick: () => onResendWelcomeRegisteredMaiilButtonClick(), disabled: resendWelcomeRegisteredMailProcessing, buttonType: "primary" },
                            React.createElement(Typography, { size: 1 }, "\u30A6\u30A7\u30EB\u30AB\u30E0\u30E1\u30FC\u30EB\u3092\u518D\u9001\u3059\u308B")))),
                isAdminVisible && (React.createElement(ContentWrapper, null,
                    React.createElement(Label, { text: "登録メールアドレス以外にウェルカムメールを送る場合、以下にメールアドレスを入力してください。", margin: "0 0 7px 0" }),
                    React.createElement(ItemWrapper, { width: "100%" },
                        React.createElement(Input, Object.assign({}, inputProps))),
                    React.createElement(ButtonWrapper, { width: "334px" },
                        React.createElement(Button, { onClick: () => onResendWelcomeMailButtonClick(otherMailAddress), disabled: resendWelcomeMailProcessing || !isFormValid, buttonType: "primary" },
                            React.createElement(Typography, { size: 1 }, "\u767B\u9332\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u4EE5\u5916\u306B\u30A6\u30A7\u30EB\u30AB\u30E0\u30E1\u30FC\u30EB\u3092\u9001\u308B"))))))),
        !!snackBar && (React.createElement(Snackbar, { isOpen: snackBar.isOpen, icon: React.createElement("img", { src: snackBar.type === "ok" ? okIcon : errorIcon, width: "18", height: "18", alt: snackBar.type }), text: snackBar.message, close: snackBar.close }))));
}
