import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_USER_STATE } from "../../../graphql/queries";
import { USER_ROLE } from "../../../utils/constants";
import { ResendWelcomeMailPage as Component, } from "../../../components/pages/medicalInstitution/ResendWelcomeMailPage";
import { useMutation } from "@apollo/client";
import { RESEND_SETUP_MAIL, RESEND_SETUP_REGISTERED_MAIL, } from "../../../graphql/mutations";
import { useLocation, useHistory } from "react-router-dom";
import { useValidation } from "../../../hooks/useValidation";
export const ResendWelcomeMailPage = () => {
    const [snackBar, setSnackBar] = useState(null);
    const { data: userData } = useQuery(GET_USER_STATE);
    const showSnackBar = (type, message) => {
        setSnackBar({
            type,
            message,
            isOpen: true,
            close() {
                setSnackBar(null);
            },
        });
    };
    const onCompletedResendMail = (result) => {
        showSnackBar("ok", `${result.resendSetupEmail.recipientEmailAddress}にウェルカムメールを再送しました。`);
    };
    const onResendMailError = () => {
        showSnackBar("error", "メール送信に失敗しました。");
    };
    const onCompletedRegisteredResendMail = (result) => {
        showSnackBar("ok", `${result.resendSetupRegisteredEmail.recipientEmailAddress}にウェルカムメールを再送しました。`);
    };
    const [resendWelcomeMail, { loading: resendWelcomeMailProcessing },] = useMutation(RESEND_SETUP_MAIL, {
        onCompleted: onCompletedResendMail,
        onError: onResendMailError,
    });
    const [resendWelcomeRegisteredMail, { loading: resendWelcomeRegisteredMailProcessing },] = useMutation(RESEND_SETUP_REGISTERED_MAIL, {
        onCompleted: onCompletedRegisteredResendMail,
        onError: onResendMailError,
    });
    const [otherMailAddress, setOtherMailAddress] = useState("");
    const { errors: mailInputErrorState, isFormValid, setTouched, } = useValidation({
        otherMailAddress: {
            value: otherMailAddress,
            rules: { email: true },
        },
    }, [otherMailAddress]);
    const location = useLocation();
    const medicalInstitutionInfo = location.state;
    const onClickResendWelcomeMail = (mailAddress) => {
        resendWelcomeMail({
            variables: {
                input: {
                    medicalInstitutionId: medicalInstitutionInfo.id,
                    recipientEmailAddress: mailAddress || medicalInstitutionInfo.mailAddress,
                },
            },
        });
    };
    const onClickResendRegisteredWelcomeMail = () => {
        resendWelcomeRegisteredMail({
            variables: {
                input: {
                    medicalInstitutionId: medicalInstitutionInfo.id,
                },
            },
        });
    };
    const history = useHistory();
    const onCancelButtonClick = () => {
        history.push(`/medicalInstitution/${medicalInstitutionInfo.id}`);
    };
    return (React.createElement(Component, Object.assign({ medicalInstitution: {
            id: medicalInstitutionInfo.id,
            name: medicalInstitutionInfo.name,
            mailAddress: medicalInstitutionInfo.mailAddress,
            representativeName: medicalInstitutionInfo.representativeName,
            address: medicalInstitutionInfo.address,
        }, onResendWelcomeMailButtonClick: onClickResendWelcomeMail, onResendWelcomeRegisteredMaiilButtonClick: onClickResendRegisteredWelcomeMail, validationErrors: mailInputErrorState, setOtherMailAddress: (mail) => {
            setTouched("otherMailAddress");
            setOtherMailAddress(mail);
        } }, {
        otherMailAddress,
        isFormValid,
        resendWelcomeMailProcessing,
        resendWelcomeRegisteredMailProcessing,
        snackBar,
        onCancelButtonClick,
    }, { isAdminVisible: (userData === null || userData === void 0 ? void 0 : userData.userState.userRole) === USER_ROLE.CS_USER })));
};
