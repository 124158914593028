import React from "react";
import { useQuery } from "@apollo/client";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useHandleError } from "../../../hooks";
import { GET_DELIVERY_ORDER } from "../../../graphql/queries";
import { CompleteDeliveryOrderPage as Component, } from "../../../components/pages/deliveryOrder/CompleteDeliveryOrderPage";
import { parseDeliveryOrder } from "../../../graphql/utils";
export const CompleteDeliveryOrderPage = () => {
    var _a;
    const history = useHistory();
    const location = useLocation();
    // @ts-ignore idが存在するのに「ない」と怒られるので
    const { id: deliveryOrderId, orderClassCode } = useParams();
    const prevId = (_a = location.state) === null || _a === void 0 ? void 0 : _a.prevPageId;
    if (!deliveryOrderId)
        throw new Error("deliveryOrderId is undefined");
    const { error, data } = useQuery(GET_DELIVERY_ORDER, {
        variables: { id: deliveryOrderId },
    });
    useHandleError(error);
    if (!(data === null || data === void 0 ? void 0 : data.deliveryOrder)) {
        return null;
    }
    const clickDeliveryOrderCompleteButton = async () => {
        if (!prevId) {
            history.push("/deliveryOrder/create");
            return;
        }
        switch (orderClassCode) {
            case "PATIENT_REPLACEMENT_SHIPPING" /* PatientReplacementShipping */:
                history.push(`/patient/${prevId}`);
                break;
            case "MEDICAL_INSTITUTION_REPLACEMENT_SHIPPING" /* MedicalInstitutionReplacementShipping */:
                history.push(`/medicalInstitution/${prevId}`);
                break;
        }
    };
    const props = {
        deliveryOrder: parseDeliveryOrder(data.deliveryOrder),
        onDeliveryOrderCompleteClick: clickDeliveryOrderCompleteButton,
    };
    return React.createElement(Component, Object.assign({}, props));
};
