import { useMutation, useQuery } from "@apollo/client";
import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useHandleError } from "../../../hooks";
import { GET_PRESCRIPTION_BY_PRESCRIPTION_CODE } from "../../../graphql/queries";
import { parsePrescriptionCancel } from "../../../graphql/utils";
import { PrescriptionCancelPage as Component, } from "../../../components/pages/prescription/PrescriptionCancelPage";
import { FORCE_CANCEL_PRESCRIPTION } from "../../../graphql/mutations";
import { useSnackBar } from "../../../hooks/useSnackBar";
export const PrescriptionCancelPage = ({}) => {
    const history = useHistory();
    const { snackBar, showOKSnackBar } = useSnackBar();
    const [isCancelPrescription, setIsCancelPrescription] = useState(false);
    const [prescriptionCode, setPrescriptionCode] = useState("");
    const { error, data, loading, refetch } = useQuery(GET_PRESCRIPTION_BY_PRESCRIPTION_CODE, {
        variables: { prescriptionCode },
    });
    const [forceCancelPrescription] = useMutation(FORCE_CANCEL_PRESCRIPTION, {
        onCompleted: (result) => {
            var _a;
            if (((_a = result.forceCancelPrescription) === null || _a === void 0 ? void 0 : _a.__typename) ===
                "ForceCancelPrescriptionError") {
                history.push({
                    pathname: `/cancelPrescription/error`,
                    state: {
                        message: `ForceCancelPrescriptionError: ${result.forceCancelPrescription.message}`,
                        prescriptionCode,
                    },
                });
            }
            else {
                refetch();
                showOKSnackBar("処方をキャンセルしました。");
                setIsCancelPrescription(false);
            }
        },
        onError: (e) => {
            history.push({
                pathname: `/cancelPrescription/error`,
                state: {
                    message: `onError: ${e.message}`,
                    prescriptionCode,
                },
            });
        },
    });
    useHandleError(error);
    const prescription = useMemo(() => {
        if (!(data === null || data === void 0 ? void 0 : data.prescriptionByPrescriptionCode)) {
            return prescriptionCode === "" ? undefined : null;
        }
        return parsePrescriptionCancel(data.prescriptionByPrescriptionCode);
    }, [data, prescriptionCode]);
    const handleSearchClick = (keyword) => {
        setPrescriptionCode(keyword);
    };
    const handleSubmitClick = async () => {
        if ((prescription === null || prescription === void 0 ? void 0 : prescription.codeStatus) === "CANCELED") {
            return alert("キャンセル済処方のためキャンセルできません");
        }
        const isOk = confirm("本当に処方をキャンセルしますか？キャンセルしたら元には戻せません。");
        if (isOk) {
            setIsCancelPrescription(true);
            await forceCancelPrescription({
                variables: {
                    input: {
                        prescriptionCode,
                    },
                },
            });
        }
    };
    const props = {
        isCancelPrescription,
        isLoading: loading,
        prescription,
        snackBar,
        onSearchClick: handleSearchClick,
        onSubmitClick: handleSubmitClick,
    };
    return React.createElement(Component, Object.assign({}, props));
};
