import Auth from "@aws-amplify/auth";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
// import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
// import { GQLQuery, GQLMutation } from "@aps/schema";
// import { GET_APS, GET_MEDICAL_INSTITUTION } from "../../graphql/queries";
// import { SIGN_OUT } from "../../graphql/mutations";
import { logout } from "../../graphql/local-mutations";
import { Header as Component, } from "../../components/organisms/Header";
// import { useHandleError, useUserData } from "../../hooks";
import { VERSION } from "../../settings";
import { GET_USER_STATE } from "../../../src/graphql/queries";
/**
 * HEADER CONTAINER
 */
export const Header = () => {
    const history = useHistory();
    const { data: userData } = useQuery(GET_USER_STATE);
    const userState = userData === null || userData === void 0 ? void 0 : userData.userState;
    // const { user } = useUserData();
    // TODO: add real data
    const user = {
        name: userState === null || userState === void 0 ? void 0 : userState.name,
    };
    // const [
    //   queryMedicalInstitution,
    //   { data: getMedicalInstitutionData, error: getMedicalInstitutionError },
    // ] = useLazyQuery<GQLQuery>(GET_MEDICAL_INSTITUTION);
    // useHandleError(getMedicalInstitutionError);
    // const [signOut, { data: signOutResult }] = useMutation<GQLMutation>(SIGN_OUT);
    // const { error: getApsError, client } = useQuery<GQLQuery>(GET_APS);
    // useHandleError(getApsError);
    const [signedOut, setSignedOut] = useState(false);
    useEffect(() => {
        if (!signedOut)
            return;
        logout();
        history.push("/logout");
    }, [signedOut, history]);
    // useEffect(() => {
    //   if (isLoggedIn) {
    //     queryMedicalInstitution();
    //   }
    // }, [queryMedicalInstitution, isLoggedIn]);
    const loggedInOnlyProps = {
        hasNotice: false,
        medicalInstitutionName: undefined,
        logoutClick: async () => {
            await Auth.signOut();
            setSignedOut(true);
        },
    };
    const props = {
        user,
        version: VERSION,
        ...((userState === null || userState === void 0 ? void 0 : userState.isLoggedIn) ? loggedInOnlyProps
            : { logoutClick: () => null }),
    };
    return React.createElement(Component, Object.assign({}, props));
};
