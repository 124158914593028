import React from "react";
import { useHistory } from "react-router-dom";
import { useListDataWithLoadMoreForDeliveryOrder } from "../../../hooks";
import { parseDeliveryOrders } from "../../../graphql/utils";
import { DeliveryOrderPage as Component, } from "../../../components/pages/deliveryOrder/DeliveryOrderPage";
export const DeliveryOrderPage = () => {
    const history = useHistory();
    const { data, variables: { order }, handlers: { onSortClick, onLoadMoreClick }, } = useListDataWithLoadMoreForDeliveryOrder();
    if (!(data === null || data === void 0 ? void 0 : data.deliveryOrders) && !(data === null || data === void 0 ? void 0 : data.deliveryOrdersWithKeywords)) {
        return null;
    }
    const props = {
        deliveryOrders: data.deliveryOrders
            ? parseDeliveryOrders(data.deliveryOrders)
            : parseDeliveryOrders(data.deliveryOrdersWithKeywords),
        order,
        onSortClick,
        onRowClick: (deliveryOrderId) => {
            history.push(`/deliveryOrder/${deliveryOrderId}`);
        },
        onLoadMoreClick,
    };
    return React.createElement(Component, Object.assign({}, props));
};
